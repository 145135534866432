import { LitElement, html, nothing } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'

import { PrimaryVariant } from '@web-nfb/frontend-static/design-system/wc/button/NFBButton'
import { stringToBooleanConverter } from '@web-nfb/frontend-static/design-system/wc/_utils'

import {
  fetchBookmarks,
  postBookmark,
  deleteBookmark,
  selectIdIsBookmarked,
  selectIdIsLoading,
  selectIdHasError
} from '@src/js/redux/bookmarksReducer'
import { store } from '@src/js/redux/store'
import {
  GTMAddToMylist,
  GTMRemoveFromMylist
} from '@src/js/types/GTM'
import { GTM_EVENTS } from '@src/js/constants'

@customElement('nfb-add-to-mylist')
export class NFBAddToMyList extends LitElement {
  @state()
  addedToMyList: boolean = false

  @state()
  myListIsLoaded: boolean = false

  @state()
  isLoading: boolean

  @state()
  hasError: boolean = false

  @property({ type: Number, attribute: 'registry-id' })
  registryId: number|null = null

  @property({ type: String, attribute: 'source-title' })
  sourceTitle: string|null = null

  @property({ converter: stringToBooleanConverter, attribute: 'is-dark' })
  isDark: boolean = false

  @property({ converter: stringToBooleanConverter, attribute: 'is-small' })
  isSmall: boolean = false

  @property({ type: String, attribute: 'login-url' })
  loginUrl: string = ''

  constructor () {
    super()
    this.stateChanged = this.stateChanged.bind(this)

    // fetchBookmark should be called only once,
    // make the call if status is at its initial value
    if (store.getState().bookmarks.status === undefined) {
      store.dispatch(fetchBookmarks())
    }
  }

  connectedCallback (): void {
    super.connectedCallback()
    store.subscribe(this.stateChanged)
    this.stateChanged()
  }

  stateChanged (): void {
    const bookmarksState = store.getState().bookmarks

    if (this.registryId) {
      this.myListIsLoaded = bookmarksState.list !== null
      this.hasError = selectIdHasError(bookmarksState, this.registryId)
      this.isLoading = selectIdIsLoading(bookmarksState, this.registryId)
      this.addedToMyList = selectIdIsBookmarked(bookmarksState, this.registryId)
    }
  }

  addToMyList (): void {
    if (this.registryId) {
      store.dispatch(postBookmark(this.registryId)).then(() => {
        if (!this.hasError) this.pushDataLayerEvent(GTM_EVENTS.ADD_TO_MYLIST)
      })
    }
  }

  removeFromMyList (): void {
    if (this.registryId) {
      store.dispatch(deleteBookmark(this.registryId)).then(() => {
        if (!this.hasError) this.pushDataLayerEvent(GTM_EVENTS.REMOVE_FROM_MYLIST)
      })
    }
  }

  pushDataLayerEvent (eventName: string): void {
    if (!this.hasError && this.registryId && this.sourceTitle) {
      window.dataLayer = window.dataLayer || []
      const data: GTMAddToMylist | GTMRemoveFromMylist = {
        event: eventName,
        nfb_version_id: this.registryId.toString(),
        nfb_version_title: this.sourceTitle
      }
      window.dataLayer?.push(data)
    }
  }

  render () {
    if (!this.myListIsLoaded) return

    const buttonLabel = this.addedToMyList
      ? window.gettext('Remove from My List')
      : window.gettext('Add to My List')

    return html`
      <nfb-button
        @click=${this.addedToMyList ? this.removeFromMyList : this.addToMyList}
        primary-variant="${PrimaryVariant.roundWithIcon}"
        icon-name="${this.addedToMyList ? 'check-mark' : 'add'}"
        is-dark="${this.isDark}"
        is-active="${this.isLoading}"
        size="${this.isSmall ? 'sm' : nothing}"
        data-ui-el="nfb-add-to-mylist-button"
        data-cy="nfb-add-to-my-list-button"
        aria-label="${buttonLabel}"
        tooltip="${buttonLabel}"
      >
      </nfb-button>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'nfb-add-to-mylist': NFBAddToMyList
  }
}
