import {css} from 'lit';
export const styles = css`.nfb-progress-bar {
  --nfb-progress-bar-fill-color: #05EE80;
}

.nfb-progress-bar[data-ui-active=true] {
  padding-bottom: 0 !important;
  max-width: 100%;
  width: 100%;
  bottom: 0;
}

.nfb-progress-bar__bar {
  background-color: var(--nfb-progress-bar-fill-color);
}

.nfb-progress-bar__bar-container {
  background-color: #c0bfbe !important;
}`;
export default styles;
