import {css} from 'lit';
export const styles = css`.nfb-atecna-poll {
  box-sizing: border-box;
  padding: 1rem;
  background: url("./static/background.jpg");
  background-size: cover;
  position: relative;
  max-width: 848px;
  margin: 0 auto;
}

.nfb-atecna-poll::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30%;
  max-width: 235px;
  aspect-ratio: 235/188;
  background: url("./static/perso.svg") no-repeat bottom right;
  background-size: contain;
}

@media screen and (max-width: 768px), print {
  .nfb-atecna-poll .nfb-atecna-poll__container {
    padding-bottom: 3rem;
  }
}
@media screen and (max-width: 480px), print {
  .nfb-atecna-poll::after {
    width: 40%;
  }
}
@media screen and (max-width: 380px), print {
  .nfb-atecna-poll .nfb-atecna-poll__container {
    padding-bottom: 1rem;
  }
  .nfb-atecna-poll::after {
    width: 30%;
  }
}
.nfb-atecna-poll__container {
  background: #fff;
  padding: 1rem;
}

.nfb-atecna-poll__title {
  font-family: "Elza Round", Georgia, serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: #000;
  margin: 0 !important;
}

.nfb-atecna-poll__content {
  font-family: "Public Sans", Georgia, serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #000;
  max-width: 30.375rem;
  hyphens: none;
}

@media screen and (max-width: 990px), print {
  .nfb-atecna-poll__content {
    max-width: 26.625rem;
  }
}
.nfb-atecna-poll__button-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 1.5rem;
  column-gap: 1rem;
}

.nfb-atecna-poll nfb-button::part(button):hover {
  border-color: #403e43 !important;
}`;
export default styles;
