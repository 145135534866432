import { LitElement, html, nothing } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'

import { store } from '@src/js/redux/store'
import { selectFilmHistoryById } from '@src/js/redux/filmHistoryReducer'

// @ts-expect-error Lit Sass import
import style from '@src/sass/wc/NFBHistoryProgressBar.sass?lit'
// @ts-expect-error Lit Sass import
import progressBarStyle from '@web-nfb/frontend-static/design-system/sass/elements/progress-bar.sass?lit'

import { stringToBooleanConverter } from '@web-nfb/frontend-static/design-system/wc/_utils'

@customElement('nfb-history-progress-bar')
export class NFBHistoryProgressBar extends LitElement {
  @property({ type: Number, attribute: 'registry-id' })
    registryId = undefined

  @property({ converter: stringToBooleanConverter, attribute: 'full-width' })
    fullWidth: false

  @state()
    percent = 0

  static styles = [progressBarStyle, style]

  constructor () {
    super()
    this.stateChanged = this.stateChanged.bind(this)
  }

  connectedCallback (): void {
    super.connectedCallback()
    store.subscribe(this.stateChanged)
    this.stateChanged()
  }

  attributeChangedCallback (name: string, _old: string | null, value: string | null): void {
    super.attributeChangedCallback(name, _old, value)
    if (name === 'registry-id' && _old !== null) {
      // If new registryId, force reset when updating percent.
      // Percent should reflect new registry id.
      this.updatePercent(true)
    }
  }

  stateChanged (): void {
    this.updatePercent()
  }

  updatePercent (withReset = false) {
    if (this.registryId) {
      const state = store.getState()
      const filmHistory = selectFilmHistoryById(state.filmHistory, this.registryId)
      if (filmHistory) {
        const filmHistoryPercent = filmHistory?.percent ?? 0
        this.percent = filmHistory?.timecode > 0 ? Math.max(filmHistoryPercent, 1) : 0
      } else if (withReset) {
        this.percent = 0
      }
    }
  }

  render () {
    return html`
    <div
      class="nfb-progress-bar ${this.fullWidth ? 'nfb-progress-bar--full-width' : ''}"
      data-ui-active="${this.percent !== 0}"
      data-related-id="${this.registryId}"
      part="nfb-progress-bar"
    >
      <div class="nfb-progress-bar__bar-container">
        <div class="nfb-progress-bar__bar" style="${this.percent !== 0 ? `width: ${this.percent}%;` : nothing}"></div>
      </div>
    </div>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'nfb-history-progress-bar': NFBHistoryProgressBar
  }
}
