import App from './App.ts'

let app = null

// Begin HMR Stuff used in dev
if (import.meta.webpackHot) {
  import.meta.webpackHot.accept()
  if (import.meta.webpackHot.data) {
    ({ app } = import.meta.webpackHot.data)
    app.destroy()

    app = new App()
    app.init()
  }
}
// End HMR Stuff used in dev

if (!app) {
  app = new App()
  document.addEventListener('DOMContentLoaded', () => app.init())
}

// Begin HMR Stuff used in dev
if (import.meta.webpackHot) {
  import.meta.webpackHot.dispose(data => {
    data.app = app
  })
}
// End HMR Stuff used in dev
