import { LitElement, html, nothing } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js'

// @ts-expect-error Lit Sass import
import styles from '@src/sass/wc/NFBPill.sass?lit'
import { stringToBooleanConverter } from '@web-nfb/frontend-static/design-system/wc/_utils'

@customElement('nfb-pill')
export default class NFBPill extends LitElement {
  @property({ converter: stringToBooleanConverter, attribute: 'is-dark' })
  isDark = false

  @property({ type: String, attribute: 'text' })
  text = ''

  static styles = [styles]

  getClasses () {
    return {
      'nfb-work-page__header-pill': true,
      'nfb-work-page__header-pill--dark': this.isDark
    }
  }

  render () {
    if (!this.text) return nothing

    return html`
      <div
        class="${classMap(this.getClasses())}"
      >
        <span>${this.text}</span>
      </div>
    `
  }
}
