import {css} from 'lit';
export const styles = css`.nfb-work-page__header-pill {
  white-space: nowrap;
  display: inline-flex;
  font-family: "Elza Round", Georgia, serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #000;
  font-size: 0.75rem;
  text-transform: uppercase;
  background-color: #fff;
  padding: 0.125rem 0.5rem;
  border-radius: 6px;
  border: 1px solid #aaa9a7;
}

.nfb-work-page__header-pill--dark {
  border: none;
}`;
export default styles;
