import { LitElement, html, nothing, css } from 'lit'
import { customElement, state, property } from 'lit/decorators.js'
import { store } from '@src/js/redux/store'
import { GTMSelectWork } from '@src/js/types/GTM'
import { GTM_EVENTS, GTM_LIST_CATEGORY, GTM_LIST_TYPE } from '@src/js/constants'
import { WorkCard, selectWorkById, selectWorkByIdList } from '@src/js/redux/worksReducer'
import normanPaths from '@src/js/helpers/paths'
import { bookmarkButtonType } from './cards/NFBDisplayWorkCard'

import { stringToBooleanConverter } from '@web-nfb/frontend-static/design-system/wc/_utils'

// @ts-expect-error Lit Sass import
import gridListStyle from '@web-nfb/frontend-static/design-system/sass/layout/grid-list.sass?lit'

export interface ContextWork {
  registry_id?: number,
  work?: {
    registry_id?: number
  }
}

@customElement('nfb-grid-list')
export default class NFBGridList extends LitElement {
  @property({ type: Array, attribute: 'registry-id-list' })
  registryIdList = []

  @property({ converter: stringToBooleanConverter, attribute: 'show-see-more' })
  showSeeMore = false

  @property({ converter: stringToBooleanConverter, attribute: 'see-more-loading' })
  seeMoreLoading = false

  @property({ converter: stringToBooleanConverter, attribute: 'include-progress-bar' })
  includeProgressBar = false

  @property({ type: Array, attribute: 'context-detail-list' })
  contextDetailList: ContextWork[] = []

  @property({ attribute: 'bookmark-button-type' })
  bookmarkButtonType: bookmarkButtonType | '' = ''

  @property({ attribute: 'list-title' })
  listTitle = ''

  @property({ attribute: 'list-id' })
  listId = ''

  @property({ attribute: 'list-category' })
  listCategory = GTM_LIST_CATEGORY.PROGRAMMING

  @property()
  loadMoreButtonClick: () => void = () => { }

  @state()
  list: WorkCard[] | null = null

  static styles = [gridListStyle, css`
    .nfb-grid-list__container {
      min-height: 200px;

      nfb-see-more {
        min-height: 300px;
      }
    }`
  ]

  async connectedCallback () {
    super.connectedCallback()
    if (store.getState().works && this.registryIdList) {
      this.list = selectWorkByIdList(store.getState().works, this.registryIdList)
    }
  }

  attributeChangedCallback (name: string, _old: string | null, value: string | null): void {
    super.attributeChangedCallback(name, _old, value)
    if (name === 'registry-id-list') {
      this.list = selectWorkByIdList(store.getState().works, this.registryIdList)
    }
  }

  disconnectedCallback (): void {
    super.disconnectedCallback()
  }

  /**
   *
   * @param work: A film, a series, a collection.
   * @returns the context work for the work, if it's a film
   * (ex: the series or collection the film is a part of).
   * Context work will be used for the thumbnail and the link of the card.
   */
  getContextWorkForWork (work: WorkCard): WorkCard | null {
    const relatedHistory = this.contextDetailList.find(h => h.registry_id === work.id)

    if (!relatedHistory?.work?.registry_id) {
      return null
    }

    const contextWork = selectWorkById(store.getState().works, relatedHistory.work?.registry_id)
    return contextWork ?? null
  }

  getSeeMoreCard () {
    if (!this.list?.length || !this.showSeeMore) return nothing

    return html`
      <nfb-see-more
        is-loading="${this.seeMoreLoading}"
        data-ui-el="carousel-card"
        .seeMoreClick="${this.loadMoreButtonClick}"
        text="${window.pgettext('User Profile', 'Load More')}"
      ></nfb-see-more>
    `
  }

  render () {
    return html`
    <div class="nfb-grid-list__container">
      ${this.list ? nothing : html`<nfb-spinner is-loading="true" size="sm"></nfb-spinner>`}
      <div class="nfb-grid-list">
    ${this.list?.map((work, index) => {
    let contextWork = null

    if (this.contextDetailList.length > 0) {
      contextWork = this.getContextWorkForWork(work)
    }

    const url = normanPaths[contextWork?.category ?? work.category]

    const gtmAttrs: GTMSelectWork = {
      event: GTM_EVENTS.SELECT_WORK,
      nfb_list_name: this.listTitle,
      nfb_list_id: this.listId,
      nfb_list_type: GTM_LIST_TYPE.GRID,
      nfb_list_category: this.listCategory,
      nfb_detail: (index + 1).toString(),
      nfb_version_title: work.title
    }

    return html`
      <nfb-display-work-card
        thumbnail-path="${contextWork?.thumbnail ?? work.thumbnail}"
        registry-id=${work.id}
        bookmark-button-type="${this.bookmarkButtonType}"
        gtm-attributes="${JSON.stringify(gtmAttrs)}"
        href="${url.replace('{slug}', contextWork?.slug ?? work.slug)}"
        include-progress-bar="${this.includeProgressBar ?? nothing}"
      >
      </nfb-display-work-card>`
  })}
    ${this.getSeeMoreCard()}
    </div>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'nfb-grid-list': NFBGridList
  }
}
